import {
  Container,
  Typography,
  Divider,
  Box,
  Button,
} from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const PurelyPalmReview = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm" sx={{ mt: 8, textAlign: "center" }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold' }}>
        <ArrowBackIosNewIcon
          onClick={() => navigate("/rentals/purely_palms")}
          className="mr-8 cursor-pointer"
        />
          REVIEWS
        </Typography>
        <Divider sx={{ my: 2, backgroundColor: '#000' }} />
      </Box>

      <Typography variant="body1" sx={{ my: 6 }}>
        No reviews (yet)
      </Typography>

      {/* <Button
        variant="outlined"
        color="primary"
        sx={{ mt: 4 }}
        href="#add-review"
      >
        Add Your Review
      </Button> */}
    </Container>
  );
};

export default PurelyPalmReview;
