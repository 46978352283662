import React, { useState, useEffect, useMemo } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axiosApiInstance from "../../store/axiosApi";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import appConfig from "../../config/config";
const stripePromise = loadStripe(appConfig.STRIPE_SK);
import { useParams } from "react-router-dom";

export default function BookingForm({ rental }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [totalPrice, setTotalPrice] = useState({
    totalNightPrice: 0,
    nights: 0,
    cleaningFee: 0,
    damageWaiverFee: 0,
    taxAmount: 0,
    finalTotalPrice: 0,
    totalBeforeTax: 0,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showContactDialog, setShowContentDialog] = useState(false);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [showBookingForm, setShowBookingForm] = useState(false); // New state for showing the form modal
  const [email, setEmail] = useState(""); // State for email input
  const [phone, setPhone] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pricesByDate, setPricesByDate] = useState([]);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [showPriceHomeDetails, setShowPriceHomeDetails] = useState(false);

  const { status = "" } = useParams();

  const toggleAmenities = () => setShowAmenities(!showAmenities);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
    if (!showDatePicker) {
      setFocusedInput("startDate");
    }
  };
  const navigate = useNavigate();
  const [showAmenities, setShowAmenities] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [guests, setGuests] = useState(1);
  const toggleBookingDialog = () => {
    setShowBookingForm(!showBookingForm);
  };
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), // Both startDate and endDate initialized as null
      key: "selection",
    },
  ]);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const images = [
    "/img/purely_logo.png",
    "/img/K0.jpg",
    "/img/K1.jpg",
    "/img/K2.jpg",
    "/img/K3.jpg",
    "/img/K4.jpg",
    "/img/K5.jpg",
    "/img/K6.jpg",
    "/img/K7.jpg",
    "/img/K8.jpg",
    "/img/K9.jpg",
  ];
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    handleResize();
    checkAvailability();
    window.addEventListener("resize", handleResize); // Check on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (status == "fail") {
      showErrorToast("The Stripe payment is failed.");
      navigate("/rentals/purely_palms/calendar");
    } else if (status == "success") {
      showSuccessToast("The  payment is success.");
      validatePurelyPalmTransaction();
    }
  }, [status]);

  const validatePurelyPalmTransaction = async () => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const session_id = queryParams.get("session_id");
    const email = queryParams.get("email");
    try {
      const postResponse = await axiosApiInstance.post(
        "/verify_purely_stripe",
        {
          session_id: session_id,
          email: email,
        }
      );
      if (postResponse.data.status == "error") {
        return;
      }
      setTimeout(() => {
        navigate("/rentals/purely_palms/calendar");
      }, 3000);
    } catch (e) {}
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1280); // Set to true for mobile views (768px and below)
  };
  const checkAvailability = async () => {
    setLoading(true);
    try {
      const response = await axiosApiInstance.post("/get-unavailable-dates", {
        rental,
      });
      const unavailableRanges = response.data.unavailableDates;

      setPricesByDate(response.data.pricesByDate);

      // Process unavailable ranges and convert the 'start' and 'end' into Date objects in EST
      const unavailableDates = unavailableRanges.flatMap((range) => {
        const startDate = moment(range.start).toDate(); // No timezone conversion, let moment handle local
        const endDate = moment(range.end).toDate();

        // Disable the end date since users leave on this date, subtract one day
        endDate.setDate(endDate.getDate() - 1);

        // If the start and end are the same day, consider the full day as unavailable
        if (startDate.getTime() === endDate.getTime()) {
          return [startDate];
        } else {
          // Return all dates in the range as unavailable
          const datesInRange = [];
          let currentDate = new Date(startDate);
          while (currentDate <= endDate) {
            datesInRange.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
          }
          return datesInRange;
        }
      });

      setUnavailableDates(unavailableDates); // Update the unavailable dates in the state
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    } finally {
      setLoading(false);
    }
  };
  const CarouselComponent = React.memo(() => (
    <Grid container spacing={2} item xs={12} md={12}>
      <Grid item xs={12}>
        <Slider
          dots
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay
        >
          {images.map((src, index) => (
            <div key={index}>
              <img
                src={src}
                alt={`Carousel image ${index}`}
                style={{ width: "100%", height: "250px", borderRadius: "10px" }}
              />
            </div>
          ))}
        </Slider>
      </Grid>
    </Grid>
  ));
  const handleRangeChange = (item) => {
    const { startDate, endDate } = item.selection;
    const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // Convert ms to days

    // if (daysDiff >= 3) {
    setRange([item.selection]);
    // } else {
    //   showErrorToast("Minimum selection is 3 nights");
    //   clearDates()
    //   return;
    // }
    const totalPrice = calculateTotalPrice(startDate, endDate);
    setTotalPrice(totalPrice);
  };
  const clearDates = () => {
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate(new Date());
    setEndDate(new Date());
  };
  const handleReserveClick = () => {
    openContactDialog();
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const formatPrice = (price) => {
    return price
      ? price.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0, // Ensure no decimals
          maximumFractionDigits: 0, // Ensure no decimals
        })
      : 0;
  };
  const validatePhone = (phone) => {
    const re = /^[0-9]{10,15}$/; // Adjust the regex based on your phone format requirements
    return re.test(phone);
  };
  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000, // Auto close after 5 seconds
      closeOnClick: true, // Close when clicked
      pauseOnHover: true, // Pause auto close when hovered
      draggable: true, // Enable draggable toast
      hideProgressBar: false, // Show progress bar
    });
  };
  // Error Toast
  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000, // Auto close after 5 seconds
      closeOnClick: true, // Close when clicked
      pauseOnHover: true, // Pause auto close when hovered
      draggable: true, // Enable draggable toast
      hideProgressBar: false, // Show progress bar
    });
  };

  const validDateData = () => {
    if (!range[0].startDate || !range[0].endDate) {
      showErrorToast("Please select a valid check-in and check-out date.");
      return false;
    }

    if (!email) {
      showErrorToast("Please enter your email address.");
      return false;
    }

    if (!validateEmail(email)) {
      showErrorToast("Please enter a valid email address.");
      return false;
    }

    if (!phone) {
      showErrorToast("Please enter your phone number.");
      return false;
    }

    if (!validatePhone(phone)) {
      showErrorToast("Please enter a valid phone number.");
      return false;
    }

    if (!firstname) {
      showErrorToast("Please enter your first name.");
      return false;
    }

    if (!lastname) {
      showErrorToast("Please enter your last name.");
      return false;
    }

    const { startDate, endDate } = range[0];
    const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // Convert ms to days

    if (daysDiff < 3) {
      showErrorToast("Minimum selection is 3 nights");
      clearDates();
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validDateData()) return;
    const totalPrice = calculateTotalPrice(startDate, endDate);
    setTotalPrice(totalPrice);

    setLoading(true);

    const bookingData = {
      email: email,
      phone: phone,
      startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      guests: guests,
      rental: rental,
      firstname,
      lastname,
    };

    try {
      const response = await axiosApiInstance.post("/book-purely", bookingData);

      if (response.status === 200) {
        showSuccessToast("Request Sent!");
        toggleBookingDialog();
        setTimeout(() => {
          location.reload(); // Optionally refresh the page or redirect
        }, 3000);
      } else {
        showErrorToast("Failed to submit booking. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
      showErrorToast(
        "Failed to submit booking. Please check your internet connection."
      );
    } finally {
      setLoading(false); // Hide loading
    }
  };

  const handleTogglePriceDetails = () => {
    setShowPriceHomeDetails(!showPriceHomeDetails); // Toggle state
  };

  const calculateTotalPrice = (startDate, endDate) => {
    if (!startDate || !endDate) return 0; // Ensure dates are valid
    let totalNightPrice = 0;
    let currentDate = new Date(startDate);
    const nights = Math.floor(
      (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
    );

    // Loop through each day in the selected range
    while (currentDate <= new Date(endDate)) {
      const month = moment(currentDate).format("MMMM");
      const dayIndex = moment(currentDate).date();
      const monthPrices = pricesByDate.find(
        (priceData) => priceData.month === month
      );
      const dayPrice = monthPrices ? monthPrices.prices[dayIndex - 1] : 0;
      totalNightPrice += dayPrice;
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Additional Fees
    const cleaningFee = 160;
    const damageWaiverFee = 65;

    // Tax calculation (only on nightly price, not on fees)
    const taxRate = 0.12; // 12% tax (6% Sales tax, 1% Walton tax, 5% Tourist tax)
    const taxAmount = totalNightPrice * taxRate;

    const totalBeforeTax = totalNightPrice + cleaningFee + damageWaiverFee;

    const finalTotalPrice =
      totalNightPrice + cleaningFee + damageWaiverFee + taxAmount;

    return {
      totalNightPrice,
      nights,
      cleaningFee,
      damageWaiverFee,
      taxAmount,
      finalTotalPrice,
      totalBeforeTax,
    };
  };
  const LoadingSpinner = () => (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50 z-[100]">
      <div className="flex flex-col items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        <p className="text-white mt-4 text-lg">Please wait...</p>
      </div>
    </div>
  );
  const handleTouchMove = (event) => {
    event.preventDefault();
  };
  const handleShowConfirmationDialog = () => {
    const { startDate, endDate } = range[0];
    const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // Convert ms to days

    if (daysDiff < 3) {
      showErrorToast("Minimum selection is 3 nights");
      return;
    }
    setShowBookingForm(true);
  };
  const handlePayment = async () => {
    if (!validDateData()) return;
    const totalPrice = calculateTotalPrice(startDate, endDate);
    setTotalPrice(totalPrice);

    setLoading(true);

    const bookingData = {
      email: email,
      phone: phone,
      startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      guests: guests,
      rental: rental,
      firstname,
      lastname,
    };

    const response = await axiosApiInstance.post(
      "/create_checkout_purely_palm",
      bookingData
    );

    const stripe = await stripePromise;
    console.log("response.data.id", response.data.id);
    const { error } = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });

    if (error) {
      console.error("Stripe error:", error);
      alert("There was an issue with the payment.");
    }
    setLoading(false);
  };
  return (
    <div className="container mx-auto max-w-screen-xl my-16">
      {loading && <LoadingSpinner />}
      <ToastContainer />
      <h1 className="text-2xl font-bold my-8">
        <ArrowBackIosNewIcon
          onClick={() => navigate("/rentals/purely_palms")}
          className="mr-8 cursor-pointer"
        />
        Sandprints C1 - Great Location! We LOVE Snowbirds
      </h1>

      {isMobile ? (
        <div>
          <CarouselComponent images={images} />
        </div>
      ) : (
        <div className="hidden lg:grid grid-cols-2 gap-2">
          <div className="col-span-1">
            <img
              src="/img/purely_background.png"
              alt="Beach View"
              className="w-full h-auto object-cover rounded-l-2xl"
            />
          </div>

          <div className="relative grid grid-cols-2 grid-rows-2 gap-2">
            <img
              src="/img/K0.jpg"
              alt="Living Room"
              className="w-full h-full object-cover"
            />
            <img
              src="/img/K1.jpg"
              alt="Kitchen"
              className="w-full h-full object-cover rounded-tr-2xl"
            />
            <img
              src="/img/K2.jpg"
              alt="Dining Room"
              className="w-full h-full object-cover"
            />
            <img
              src="/img/K3.jpg"
              alt="Bedroom"
              className="w-full h-full object-cover rounded-br-2xl"
            />
            <button
              className="px-3 py-1 bg-white border-1 border-black text-black rounded-md hover:bg-gray-600 absolute bottom-3 right-5"
              onClick={toggleModal}
            >
              Show all photos
            </button>
          </div>
        </div>
      )}

      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={toggleModal} // Close modal when clicking outside the content area
        >
          <div
            className="bg-white p-8 rounded-lg relative max-w-4xl w-full max-h-screen overflow-y-auto"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside the content area
          >
            {/* Image grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <img
                src="/img/purely_logo.png"
                alt="Beach View"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K0.jpg"
                alt="Living Room"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K1.jpg"
                alt="Kitchen"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K2.jpg"
                alt="Dining Room"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K3.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K4.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K5.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K6.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K7.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K8.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K9.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
              <img
                src="/img/K10.jpg"
                alt="Bedroom"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>
        </div>
      )}

      <div className="my-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Information Section */}
          <div className={`${isMobile ? "mb-8" : "lg:col-span-2"}`}>
            <h2 className="text-2xl font-bold">
              Entire condo in Miramar Beach, Florida
            </h2>
            <p>4 guests · 1 bedroom · 2 beds · 1 bath</p>
            <p className="mt-4">
              Experience the ultimate in beach-inspired living at Purely Palms @
              Miramar Beach. Our 1 bed 1 bath condo has been updated, offering a
              clean, modern and relaxing atmosphere with a very short walk to
              the white sands of the Emerald Coast. This centrally located
              ground floor unit has convenient, hassle-free access to your
              reserved parking right outside the front door. Plenty of
              additional parking available in complex. Full washer and dryer is
              included in unit! Snowbirds Welcome!
            </p>

            {/* Where you'll sleep */}
            {/* <div className="mt-8">
              <h3 className="font-bold text-lg">Where you’ll sleep</h3>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="p-4 border rounded-lg">
                  <p className="font-semibold">Bedroom</p>
                  <p className="text-gray-500">1 queen bed</p>
                </div>
                <div className="p-4 border rounded-lg">
                  <p className="font-semibold">Living room</p>
                  <p className="text-gray-500">1 sofa bed</p>
                </div>
              </div> */}
            {/* </div> */}

            {/* What this place offers */}
            {/* <div className="mt-8">
              <h3 className="font-bold text-lg">What this place offers</h3>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <p>Shared beach access</p>
                <p>Kitchen</p>
                <p>Wifi</p>
                <p>Free parking</p>
                <p>Washer - in unit</p>
                <p>TV</p>
                <p>Dryer</p>
              </div>
              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={toggleAmenities}
              >
                Show all amenities
              </button>
            </div> */}
          </div>

          {/* Right Reservation Section */}
          <div className="relative">
            <div className="lg:sticky top-16">
              <div className="border rounded-lg p-4 shadow-lg bg-white">
                <div className="flex items-center justify-between">
                  <p className="text-xl font-semibold"></p>
                  <p className="line-through text-gray-500"></p>
                </div>
                <div className="mt-4">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={toggleDatePicker}
                  >
                    <div>
                      <p className="text-gray-500 text-xs">Check-in</p>
                      <p>
                        {range[0].startDate
                          ? range[0].startDate.toLocaleDateString()
                          : "Add date"}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-500 text-xs">Check-out</p>
                      <p>
                        {range[0].endDate
                          ? range[0].endDate.toLocaleDateString()
                          : "Add date"}
                      </p>
                    </div>
                  </div>

                  <div className="my-8 relative">
                    <div
                      onTouchMove={handleTouchMove} // Prevent page scrolling while interacting with the calendar
                      className="calendar-container"
                    >
                      <DateRange
                        onChange={handleRangeChange}
                        ranges={range}
                        minDate={new Date()}
                        months={1}
                        direction="horizontal"
                        showDateDisplay={false}
                        disabledDates={unavailableDates}
                        className="mt-4"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 bg-gray-50 p-4 rounded-md shadow-md w-full">
                  <div className="flex justify-between items-center">
                    <p>Guests</p>
                    <select
                      value={guests}
                      onChange={(e) => setGuests(e.target.value)}
                      className="border p-2 rounded-md w-full ml-4"
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                    </select>
                  </div>
                  <div className="flex justify-between mt-4">
                    <p>{`Nights`}</p>
                    <p>{totalPrice?.nights} </p>
                    {/* <p>{`${formatPrice(totalPrice?.totalNightPrice)}`}</p> */}
                  </div>
                  <div className="flex justify-between font-bold mt-4">
                    <p onClick={handleTogglePriceDetails}>
                      Total before taxes{" "}
                      <span className="underline cursor-pointer">
                        Price Details
                      </span>
                    </p>
                    <p>{formatPrice(totalPrice?.totalBeforeTax)}</p>
                  </div>
                </div>
                {/* Price Details dropdown with animation */}
                <div
                  className={`overflow-hidden transition-all duration-500 ease-in-out ${
                    showPriceHomeDetails
                      ? "max-h-full opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  {showPriceHomeDetails && (
                    <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-md space-y-4">
                      <div className="flex justify-between text-sm">
                        <span className="font-semibold">Check-in:</span>
                        <span>{range[0].startDate?.toLocaleDateString()}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="font-semibold">Check-out:</span>
                        <span>{range[0].endDate?.toLocaleDateString()}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="font-semibold">Guests:</span>
                        <span>{guests}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="font-semibold">Total Nights:</span>
                        <span>{totalPrice?.nights}</span>
                      </div>
                      {/* <div className="flex justify-between text-sm">
                        <span className="font-semibold">
                          Total Nightly Rate:
                        </span>
                        <span>{formatPrice(totalPrice?.totalNightPrice)}</span>
                      </div> */}

                      <hr className="border-t border-gray-200 my-2" />

                      <div className="flex justify-between text-sm">
                        <span className="font-semibold">Cleaning Fee:</span>
                        <span>{formatPrice(totalPrice?.cleaningFee)}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="font-semibold">Damage Waiver:</span>
                        <span>{formatPrice(totalPrice?.damageWaiverFee)}</span>
                      </div>

                      <hr className="border-t border-gray-200 my-2" />

                      <div className="flex justify-between text-lg font-bold">
                        <span>Total With Fees:</span>
                        <span>{formatPrice(totalPrice?.totalBeforeTax)}</span>
                      </div>

                      <div
                        className="flex justify-end cursor-pointer mt-4 text-gray-500"
                        onClick={handleTogglePriceDetails} // Use the same handler to close
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <span className="ml-2">Close</span>
                      </div>
                    </div>
                  )}
                </div>

                <button
                  className="w-full mt-6 bg-pink-500 text-white py-3 px-4 rounded-lg hover:bg-pink-600"
                  onClick={handleShowConfirmationDialog}
                >
                  Reserve
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="my-16">
          <hr />
          <div className="my-8">
            <h2 className="text-2xl font-bold">No reviews (yet)</h2>
          </div>

          {/* Section 2: Location (Where you'll be) */}
          <div className="my-8">
            <hr className="my-16" />
            <h3 className="text-xl font-bold my-8">Where you'll be</h3>
            <p className="text-gray-500 my-4">
              Miramar Beach, Florida, United States
            </p>

            {/* Google Maps Integration */}
            <div className="h-96 mt-4">
              <LoadScript googleMapsApiKey="AIzaSyD-XgMXEcxxhOGZ1nNhxtDTGLj9UeVrV4c">
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  center={{ lat: 30.38052526097164, lng: -86.38636708602937 }}
                  zoom={12}
                >
                  <Marker
                    position={{
                      lat: 30.38052526097164,
                      lng: -86.38636708602937,
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>

        {showBookingForm && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
            onClick={toggleBookingDialog}
          >
            <div
              className="bg-white p-8 rounded-lg max-w-2xl w-full relative overflow-y-auto"
              style={{ maxHeight: "100vh" }}
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="text-xl font-bold mb-4">
                {isMobile && (
                  <ArrowBackIosNewIcon
                    onClick={() => setShowBookingForm(false)}
                    className="mr-1 cursor-pointer"
                    style={{ marginLeft: "-30px" }}
                  />
                )}
                Confirm your Booking
              </h2>

              <div className="mb-4">
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastname"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              {/* Contact information form */}
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              {/* Total Price Display */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <div className="font-bold text-2xl">
                    Total:
                    {formatPrice(totalPrice?.finalTotalPrice)}
                  </div>
                  <div
                    className="cursor-pointer text-blue-600 underline flex items-center space-x-2"
                    onClick={() => setShowPriceDetails(!showPriceDetails)}
                  >
                    <span>Price details</span>
                    <svg
                      className={`w-5 h-5 transform transition-transform duration-300 ${
                        showPriceDetails ? "rotate-180" : "rotate-0"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-gray-500 text-sm">
                  Total before taxes
                </p>
              </div>

              {/* Price Details with Drop-down Animation */}
              <div
                className={`overflow-hidden transition-all my-4 duration-500 ease-in-out ${
                  showPriceDetails
                    ? "max-h-full opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                {showPriceDetails && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-md space-y-4">
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Check-in:</span>
                      <span>{range[0].startDate?.toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Check-out:</span>
                      <span>{range[0].endDate?.toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Guests:</span>
                      <span>{guests}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Total Nights:</span>
                      <span>{totalPrice.nights}</span>
                    </div>
                    {/* <div className="flex justify-between text-sm">
                      <span className="font-semibold">Total Nightly Rate:</span>
                      <span>{formatPrice(totalPrice.totalNightPrice)}</span>
                    </div> */}

                    <hr className="border-t border-gray-200 my-2" />

                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Cleaning Fee:</span>
                      <span>{formatPrice(totalPrice.cleaningFee)}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Damage Waiver:</span>
                      <span>{formatPrice(totalPrice.damageWaiverFee)}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">
                        Tax (12% on nightly rate):
                      </span>
                      <span>{formatPrice(totalPrice?.taxAmount)}</span>
                    </div>

                    <hr className="border-t border-gray-200 my-2" />

                    <div className="flex justify-between text-lg font-bold">
                      <span>Total With Fees:</span>
                      <span>{formatPrice(totalPrice?.finalTotalPrice)}</span>
                    </div>
                  </div>
                )}
              </div>

              {/* Additional form fields for Last Name, Email, Phone */}

              <div className="flex space-x-4">
                <button
                  className="w-full bg-pink-500 text-white py-2 px-4 rounded-lg hover:bg-pink-600"
                  onClick={handlePayment}
                >
                  Pay Now
                </button>
                <button
                  className="w-full bg-pink-500 text-white py-2 px-4 rounded-lg hover:bg-pink-600"
                  onClick={() => setShowBookingForm(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
